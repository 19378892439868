body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Arvo';
  src: url('fonts/Arvo-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Arvo';
  src: url('fonts/Arvo-Italic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'Arvo';
  src: url('fonts/Arvo-Bold.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Arvo';
  src: url('fonts/Arvo-BoldItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

h1 {
  font-family: 'Arvo';
  font-weight: bold;
  font-size: 24px;
}

h2 {
  font-family: 'Arvo';
  font-weight: bold;
  font-size: 18px;
}

h3 {
  font-family: 'Arvo';
  font-weight: bold;
  font-size: 14px;
}

h4 {
  font-family: 'Arvo';
  font-weight: bold;
  font-size: 12px;
}

h5 {
  font-family: 'Arvo';
  font-weight: bold;
  font-size: 11px;
}

h6 {
  font-family: 'Arvo';
  font-weight: bold;
  font-size: 11px;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #909090;
}
